import './App.scss';
import React from 'react';
import Customer from './Customer';
import Staff from './Staff';
import Pusher from "pusher-js";

const pusher = new Pusher('a253cc58ad0a469104a8', {
  cluster: 'ap3',
  encrypted: true
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: window.location.search.indexOf('staff') !== -1 ? 'staff' : 'customer'
    }
  }

  render() {
    const {type} = this.state;
    if (type === 'customer') {
      return <Customer pusher={pusher}/>
    } else {
      return <Staff pusher={pusher}/>
    }
  }
}

export default App;
