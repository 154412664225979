import './Staff.scss';

import './App.scss';
import React from 'react';
import axios from 'axios'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import ChatWindowInner from './ChatWindowInner';
import SessionList from "./SessionList";
import * as microsoftTeams from "@microsoft/teams-js";

class LeftRail extends React.Component {
  render() {
    return (
      <div className="left-rail">
        {this.props.children}
      </div>
    )
  }
}

class RailHeader extends React.Component {
  render() {
    return (
      <div className="rail-header">
        {this.props.children}
      </div>
    )
  }
}

class Staff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      userId: null,
      sessionUuid: null,
      session: {}
    };

    axios.post('https://teadesk.musicapps.cn/api/teadesk/login', {
      name: "Bo Lu",
      uuid: "8:orgid:aa2a7b29-715b-401c-aa01-d0df2b8a08b5",
      email: "bolu1@microsoft.com",
      avatar: "https://azure.musicapps.cn/lbavatar.jpg"
    }).then(({data}) => {
      axios.defaults.headers.common = {
        "Access-Token": data.authToken,
      };
      this.setState({
        user: data,
        userId: data.id,
      })
    })

    this.handleSessionSwitch = this.handleSessionSwitch.bind(this)

    if (microsoftTeams) {
      if (window.parent !== window.self) {
        console.log('initial teams')
        microsoftTeams.initialize();
        microsoftTeams.settings.getSettings((instanceSettings) => {
          console.log(instanceSettings)
        });
        microsoftTeams.registerFullScreenHandler((isFullScreen) => {
          console.log("full Screen", isFullScreen)
        })
        microsoftTeams.enterFullscreen();
      }
    }
  }

  handleSessionSwitch(session) {
    this.setState({
      sessionUuid: session.uuid,
      session
    })
  }

  render() {
    return (
      <div className="App">
        <div className="workspace">
          <LeftRail>
            <RailHeader>
              <div className="title">Teadesk</div>
            </RailHeader>
            <PerfectScrollbar containerRef={(ref) => {
              this._scrollRef = ref
            }}>
              <SessionList
                pusher={this.props.pusher}
                getSessionUrl='https://teadesk.musicapps.cn/api/teadesk/sessions/all'
                user={this.state.user}
                userId={this.state.userId}
                onSwitchSession={this.handleSessionSwitch}
                listenChannel={this.state.userId ? ('mySessions-' + this.state.userId) : null}
              />
            </PerfectScrollbar>
          </LeftRail>
          <ChatWindowInner
            pusher={this.props.pusher}
            showAvatar={true}
            user_id={this.state.userId}
            sessionUuid={this.state.sessionUuid}
            session={this.state.session}/>
        </div>
      </div>
    );
  }
}

export default Staff;
