import React from "react";
import axios from "axios";
import SessionItem from "./SessionItem";
import {notifyMe} from "./Common";
import "./SessionList.scss"

class SessionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      selectedSessionId: null
    }
    this.getSessionUrl = this.props.getSessionUrl;

    if (props.user.authToken) {
      this.loadList();
    }
    this.handleSessionItemClick = this.handleSessionItemClick.bind(this)
    this.pusher = this.props.pusher;
    this.subscribePusher()
  }

  subscribePusher() {
    if (!this.props.listenChannel) {
      return;
    }
    const channel = this.pusher.subscribe(this.props.listenChannel)
    channel.bind('sessionCreated', data => {
      this.updateSession(data)
      notifyMe('New session arrived!', "From: " + data.name);
    });
    channel.bind('sessionUpdated', data => {
      this.updateSession(data)
      if (data.id !== this.state.selectedSessionId) {
        notifyMe('New message in session!', "From: " + data.name);
      }
    })
  }

  unsubscribePusher() {
    if (!this.props.listenChannel) {
      return;
    }
    this.pusher.unsubscribe(this.props.listenChannel)
  }


  componentWillUnmount() {
    this.unsubscribePusher();
  }

  updateSession(session) {
    let found = false;
    this.setState({
      sessions: this.state.sessions.map((elem) => {
        if (elem.id === session.id || elem.uuid === session.uuid) {
          found = true;
          return {...elem, ...session}
        }
        return elem;
      }).sort((a, b) => {
        return new Date(b.latest_messaged_at) - new Date(a.latest_messaged_at);
      })
    })
    if (!found) {
      this.prependSession(session)
    }
  }

  prependSession(session) {
    this.setState({
      sessions: [
        session,
        ...this.state.sessions
      ]
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.loadList(() => {
        this.switchToFirstSession();
      })
    }

    if (prevProps.listenChannel !== this.props.listenChannel) {
      this.subscribePusher();
    }
  }

  switchToFirstSession() {
    const sessions = this.state.sessions;
    if (sessions.length > 0) {
      this.setState({
        selectedSessionId: sessions[0].id
      })
      this.props.onSwitchSession(sessions[0])
    }
  }

  loadList(callback) {
    axios.get(this.getSessionUrl, {
      params: {
        expand: 'responsibleStaff,latestMessage.user,avatar'
      }
    }).then(({data}) => {
      this.setState({
        sessions: data
      })
      if (callback) {
        callback()
      }
    })
  }

  unsetSessionEmphasis(session) {
    axios.put("https://teadesk.musicapps.cn/api/teadesk/sessions/" + session.uuid, {
      show_emphasis: false
    })
  }

  handleSessionItemClick(session) {
    console.log(session)
    this.setState({
      selectedSessionId: session.id,
      sessions: this.state.sessions.map((elem) => {
        if (elem.id === session.id) {
          return {...elem, show_emphasis: false}
        }
        return elem;
      })
    })
    this.unsetSessionEmphasis(session)
    this.props.onSwitchSession(session)
  }

  render() {
    return (
      <div className="session-list">
        {this.state.sessions.map((session) => {
          return <SessionItem
            active={session.id === this.state.selectedSessionId}
            key={session.id}
            session={session}
            onClick={this.handleSessionItemClick}
          />
        })}
      </div>
    )
  }
}

export default SessionList;