let lastMessage = null;

export function notifyMe(title, body = null, force = false) {
  if (document.hasFocus()) {
    return;
  }
  if (!("Notification" in window)) {
    return;
  }
  if (body !== null && body === lastMessage) {
    return;
  }
  lastMessage = body;
  console.log(title, body, Notification.permission)
  if (Notification.permission === "granted" || force) {
    let options = {};
    let siteIcon = document.querySelectorAll('[rel=icon]');
    if (siteIcon.length > 0) {
      options['icon'] = siteIcon[0].getAttribute('href')
    }
    if (body) {
      options['body'] = body;
    }
    let noti = new Notification(title, options);
    noti.onclick = function (e) {
      window.focus();
      this.close();
    }
  } else if (Notification.permission !== 'denied') {
    console.log('request')
    Notification.requestPermission(function (permission) {
      // 如果用户同意，就可以向他们发送通知
      if (permission === "granted") {
        notifyMe(title, body, true)
      }
    });
  }
}

