import React from "react";
import './ChatMessage.scss';

class ChatMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {me, message, showAvatar} = this.props;
    const name = message.user ? message.user.display_name : message.session.user;
    const avatar = message.user ? message.user.avatar_url : message.session.avatar;
    return (
      <div
        className={(me ? 'me' : '') + (showAvatar ? " show-avatar " : '') + ' chat-message'}>
        {showAvatar ? <img src={avatar} alt="" className="avatar"/> : null}

        <div className="message-bubble">
          <div className="meta">
            {me ? null : <div className="user">{name}</div>}

            <div className="time">{message.created_at}</div>
          </div>
          <div className="content">
            {message.content}
          </div>
        </div>
      </div>
    )
  }
}

export default ChatMessage;
