import React from "react";
import Pusher from "pusher-js";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatMessage from "./ChatMessage";
import {TextField} from "office-ui-fabric-react/lib/TextField";
import {PrimaryButton} from "@fluentui/react";
import "./ChatWindowInner.scss";
import {notifyMe} from "./Common"

class ChatWindowInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      messages: []
    }
    this.newMessageIds = {};
    this.handleContentChange = this.handleContentChange.bind(this)
    this.submitMessage = this.submitMessage.bind(this)
    this._scrollRef = null;
    this.reloadMessages()

    this.pusher = this.props.pusher
    this.subscribeSession(this.props.sessionUuid)
  }

  subscribeSession(uuid) {
    if (!uuid) {
      return;
    }
    const channel = this.pusher.subscribe(uuid);
    console.log('subscribe to session')
    channel.bind('message', data => {
      notifyMe("New message arrived!", data.content)
      this.appendMessage(data)
    });
  }

  unsubscribeSession(uuid) {
    if (!uuid) {
      return;
    }
    this.pusher.unsubscribe(uuid);
  }

  componentWillUnmount() {
    this.unsubscribeSession(this.props.sessionUuid)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.sessionUuid !== this.props.sessionUuid) {
      this.reloadMessages()
      this.unsubscribeSession(prevProps.sessionUuid)
      this.subscribeSession(this.props.sessionUuid);
    }
  }

  appendMessage(message) {
    if (this.newMessageIds[message.id]) {
      return;
    }
    for (let i = this.state.messages.length - 1; i >= Math.max(this.state.messages.length - 10, 0); --i) {
      if (this.state.messages[i].id === message.id) {
        return;
      }
    }
    this.newMessageIds[message.id] = true;
    this.setState({
      messages: [...this.state.messages, message],
    })
    this.scrollToBottom();
  }

  reloadMessages() {
    if (!this.props.sessionUuid) {
      return;
    }
    axios.get('https://teadesk.musicapps.cn/api/teadesk/messages/all', {
      params: {
        session_id: this.props.sessionUuid,
        expand: 'user,session.avatar'
      }
    }).then(({data}) => {
      this.setState({
        messages: data,
      })
      this.scrollToBottom();
    })
  }

  scrollToBottom() {
    if (this._scrollRef) {
      this._scrollRef.scrollTop = 10000000;
    }
  }

  handleContentChange(event) {
    this.setState({content: event.target.value});
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  submitMessage(event) {
    if (this.state.content.length === 0) {
      return;
    }

    axios.post('https://teadesk.musicapps.cn/api/teadesk/messages?expand=user,session.avatar', {
      session_id: this.props.session.uuid,
      content: this.state.content
    }).then(({data}) => {
      this.appendMessage(data)

      this.setState({
        content: ''
      })
      this.scrollToBottom();
    })
  }

  render() {
    const {session, showAvatar} = this.props;
    const {responsibleStaff} = session;
    let headerInfo = {
      avatar: "https://via.placeholder.com/150/"
    };
    if (this.props.user_id) {
      headerInfo = {
        avatar: session.avatar,
        name: session.name,
        welcome: session.subject
      }
    } else {
      if (responsibleStaff) {
        headerInfo = {
          avatar: responsibleStaff.avatar_url,
          name: responsibleStaff.display_name,
          welcome: 'Have a question?'
        }
      }
    }

    return (
      <div className="chat-window-inner">

        <div className="chat-staff">

          <img src={headerInfo.avatar} alt="" className="avatar"/>
          <div className="staff-brief">
            <div className="staff-name">{headerInfo.name}</div>
            <div className="welcome-line">{headerInfo.welcome}</div>
          </div>
        </div>
        <div className="thread-wrap">
          <PerfectScrollbar containerRef={(ref) => {
            this._scrollRef = ref
          }}>
            {this.state.messages.map((message) => {
              return <ChatMessage
                showAvatar={showAvatar}
                key={message.id}
                me={message.user_id == this.props.user_id}
                message={message}/>
            })}
          </PerfectScrollbar>
        </div>
        <div className="input-form">
          <TextField value={this.state.content} multiline onChange={this.handleContentChange}
                     placeholder="Type a message here..." borderless resizable={false} autoAdjustHeight/>
          <div className="action-bar">
            <div className="toolbar"></div>
            <PrimaryButton onClick={this.submitMessage}>Send</PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatWindowInner;
