import './Customer.scss';
import React from 'react';
import {PrimaryButton} from '@fluentui/react';
import {TextField} from 'office-ui-fabric-react/lib/TextField';
import axios from 'axios'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import Pusher from 'pusher-js';
import ChatMessage from './ChatMessage';
import ChatWindowInner from './ChatWindowInner';
import ReactDOM from "react-dom";

class ChatWindow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="chat-window">
        <div className="chat-title-bar">
          <div className="chat-title">Chat through Teadesk</div>
        </div>
        <ChatWindowInner {...this.props}></ChatWindowInner>
      </div>
    );
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      telephone: null,
      subject: null,
      email: null,
      disableSubmit: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTelephoneChange = this.handleTelephoneChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event, newValue) {
    this.setState({name: newValue});
  }

  handleTelephoneChange(event, newValue) {
    this.setState({telephone: newValue});
  }

  handleSubjectChange(event, newValue) {
    this.setState({subject: newValue});
  }

  async handleSubmit() {
    if (this.state.disableSubmit) {
      return;
    }
    this.setState({
      disableSubmit: true,
    });

    const {data} = await axios.post('https://teadesk.musicapps.cn/api/teadesk/sessions', {
      name: this.state.name,
      telephone: this.state.telephone,
      subject: this.state.subject,
      email: this.state.email,
    }, {
      params: {
        expand: 'responsibleStaff,latestMessage.user,avatar'
      }
    })

    this.setState({
      disableSubmit: false,
    });
    this.props.onSessionCreated(data)
  }

  render() {
    return (
      <div className="chat-window form-window">
        <div className="chat-title-bar">
          <div className="chat-title">Chat through Teadesk</div>
        </div>

        <div className="start-session-form">

          <TextField label="Your name" value={this.state.name} onChange={this.handleNameChange}/>
          <TextField label="Phone number" value={this.state.telephone} onChange={this.handleTelephoneChange}/>
          <TextField label="What do you want to chat?" onChange={this.handleSubjectChange} multiline autoAdjustHeight/>

          <PrimaryButton onClick={this.handleSubmit} disabled={this.state.disableSubmit}>Let's chat!</PrimaryButton>
        </div>
      </div>
    );
  }
}

class Customer extends React.Component {
  constructor(props) {
    super(props);
    const sessionUuid = localStorage.getItem('sessionUuid');

    this.state = {
      sessionUuid,
      session: {}
    };

    this.handleSessionCreated = this.handleSessionCreated.bind(this);
    if (sessionUuid) {
      axios.get('https://teadesk.musicapps.cn/api/teadesk/sessions/' + sessionUuid, {
        params: {
          expand: 'responsibleStaff,latestMessage.user,avatar'
        }
      }).then(({data}) => {
        this.setState({
          session: data
        })
      })
    }
  }

  handleSessionCreated(data) {
    this.setState({
      session: data,
      sessionUuid: data.uuid
    })
    localStorage.setItem('sessionUuid', data.uuid);
  }

  render() {
    return (
      <div className="App CustomerApp">
        {this.state.sessionUuid ?
          <ChatWindow pusher={this.props.pusher}
                      sessionUuid={this.state.sessionUuid}
                      session={this.state.session}/> :
          <Form onSessionCreated={this.handleSessionCreated}/>
        }
      </div>
    );
  }
}

export default Customer;
