import React from "react";
import "./SessionItem.scss"

class SessionItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.onClick(this.props.session)
  }

  render() {
    const {session} = this.props;
    return (
      <div
        className={(this.props.active ? 'active ' : "") + (session.show_emphasis ? " emphasis " : '') + 'session-item'}
        onClick={this.handleClick}>
        <img className='avatar' src={session.avatar} alt=""/>
        <div className="content">
          <div className="title">
            <div className="name">{session.name}</div>
            <div className="time">{session.latest_messaged_at}</div>
          </div>
          <div className="latest-message">
            {session.latestMessage ? (
              (session.latestMessage.user ? session.latestMessage.user.display_name : session.name) + ': ' +
              session.latestMessage.content
            ) : (session.name + ": " + session.subject)}

          </div>
        </div>
      </div>
    )
  }
}

export default SessionItem